/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Link, Text } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
export default function Variety({ variety, mode = 'small', ...rest }) {
  const titleVariant = mode === 'small' ? 'gridLabel' : 'largeGridLabel';
  return (
    <Link
      fallback="div"
      to={`/products#mainVarieties=${variety.name}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'center',
        transition: 'none',
        border: 2,
        padding: 2,
        color: `variety.${variety.wineColor}.text`,
        borderColor: `variety.${variety.wineColor}.border`,
        backgroundColor: `variety.${variety.wineColor}.border`,
        ':hover': {
          color: variety.wineColor === 'white' ? 'black' : 'white',
          borderColor: 'black',
        },
      }}
      {...rest}
    >
      <Text variant={titleVariant}>{variety.name}</Text>
      <Text variant="smallest">
        {/* <Plural value={variety.productCount} one="# wine" other="# wines" /> */}
        {variety.productCount === 1
          ? '1 wine'
          : `${variety.productCount} wines`}
      </Text>
    </Link>
  );
}

export const fragment = graphql`
  fragment Variety on Bottlebooks_RegisteredProduct {
    product {
      ... on Bottlebooks_Wine {
        wineColor(format: RAW)
      }
    }
  }
`;

Variety.fragment = gql`
  fragment Variety on RegisteredProduct {
    name
    ... on Wine {
      grapeVarieties {
        varietyName
      }
    }
  }
`;
