/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { gql } from '@apollo/client';
import { Grid } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Variety from '../Variety';
export default function ExploreWinesByVariety({
  varieties,
  width = 110,
  height = 110,
  mode = 'small',
  ...rest
}) {
  if (!varieties) return null;
  return (
    <Grid width={width} height={height} gap={3} {...rest}>
      {varieties.map((variety) => (
        <Variety
          key={`${variety.registrationId}:${variety.productId}`}
          variety={variety}
          mode={mode}
        />
      ))}
    </Grid>
  );
}

export const fragment = graphql`
  fragment ExploreWinesByVariety on Bottlebooks_RegisteredProduct {
    registrationId
    productId
    ...Variety
  }
`;
ExploreWinesByVariety.fragment = gql`
  fragment ExploreWinesByVariety on RegisteredProduct {
    registrationId
    brandId
    ...Variety
  }
  ${Variety.fragment}
`;
