/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Box,
  Container,
  Link,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import SiteSearchSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/SiteSearchSection';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ExploreWinesByVariety from '../../components/ExploreWines/ExploreWinesByVariety';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';

export default function VarietiesPage({ pageContext, data }) {
  const { event, topWhite, topRed, all } = afterQuery(data.bottlebooks);

  return (
    <LinkProvider value={pageContext.paths}>
      <Layout>
        <EventBanner event={event} withOverlay={false} />
        <SiteSearchSection locale={pageContext.locale} />
        <Container>
          <ExploreWinesSwitcher>
            <Link to="/products">
              <Text>
                Or search by vegan, organic, on-trade and 10 other filters….
              </Text>
            </Link>
          </ExploreWinesSwitcher>
          <Box sx={{ marginTop: 3 }}>
            <Title variant="small">Top White Varieties</Title>
            <ExploreWinesByVariety
              varieties={topWhite}
              width={150}
              height={125}
              mode="large"
              sx={{ marginTop: 2, marginBottom: 4 }}
            />
            <Title variant="small">Top Red Varieties</Title>
            <ExploreWinesByVariety
              varieties={topRed}
              width={150}
              height={125}
              mode="large"
              sx={{ marginTop: 2, marginBottom: 4 }}
            />
            <Title variant="small">All Varieties</Title>
            <ExploreWinesByVariety
              varieties={all}
              mode="small"
              sx={{ marginTop: 2, marginBottom: 4 }}
            />
          </Box>
        </Container>
      </Layout>
    </LinkProvider>
  );
}

export const query = graphql`
  query VarietiesPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      theme: event(eventId: "6048b4376a7c621a467c85d5", locale: $locale) {
        name
        ...bb_EventBanner
      }
      importers: event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        ...VarietiesPage_Fragment
      }
      exporters: event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        ...VarietiesPage_Fragment
      }
    }
  }
  fragment VarietiesPage_Fragment on Bottlebooks_Event {
    registeredProducts {
      byColor: group(
        field: wineColor
        sort: { fields: totalCount, order: DESC }
      ) {
        color: fieldValue
        details: group(field: wineColor, first: 1) {
          nodes {
            product {
              ... on Bottlebooks_Wine {
                wineColor(format: RAW)
              }
            }
          }
        }
        byVariety: group(
          field: primaryVarietyName
          first: 1
          sort: { fields: totalCount, order: DESC }
        ) {
          name: fieldValue
          productCount: totalCount
          nodes {
            registrationId
            product {
              productId
              ... on Bottlebooks_Wine {
                wineColor(format: RAW)
              }
            }
          }
        }
      }
      byVariety: group(
        field: primaryVarietyName
        sort: { fields: fieldValue }
      ) {
        name: fieldValue
        productCount: totalCount

        byColor: group(
          field: wineColor
          first: 1
          sort: { fields: totalCount, order: DESC }
        ) {
          nodes {
            registrationId
            product {
              productId
              ... on Bottlebooks_Wine {
                wineColor(format: RAW)
              }
            }
          }
        }
      }
    }
  }
`;

function afterQuery(data) {
  const { theme, importers, exporters } = data;

  // Denormalize data
  const importersAll = importers.registeredProducts?.byVariety?.map(
    ({ byColor, ...rest }) => ({
      wineColor: byColor?.[0]?.nodes?.[0]?.product?.wineColor,
      ...rest,
    })
  );
  const exportersAll = exporters.registeredProducts?.byVariety?.map(
    ({ byColor, ...rest }) => ({
      wineColor: byColor?.[0]?.nodes?.[0]?.product?.wineColor,
      ...rest,
    })
  );
  const all = Object.values(
    importersAll.concat(exportersAll).reduce((acc, variety) => {
      if (!variety.name) return acc;
      const match = acc[variety.name];
      if (!match) acc[variety.name] = variety;
      else acc[variety.name].productCount += match.productCount;
      return acc;
    }, {})
  )
    // Ensure only real varieties are visible
    .filter((variety) => variety.name !== 'Other varieties')
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  // Prepare top reds
  const red = all
    ?.filter(({ wineColor }) => wineColor === 'red')
    .sort((a, b) => (a.productCount < b.productCount ? 1 : -1));
  const topRed = red?.slice(0, 5);

  // Prepare top whites
  const white = all
    ?.filter(({ wineColor }) => wineColor === 'white')
    .sort((a, b) => (a.productCount < b.productCount ? 1 : -1));

  const topWhite = white?.slice(0, 5);
  return { event: theme, topRed, topWhite, all };
}
